<template>
  <b-sidebar
    id="add-new-driver-card-sidebar"
    :visible="isAddNewDriverCardSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-driver-card-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Додати нові рахунки
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Повне імʼя"
          >
            <b-form-group
              label="Повне імʼя"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="driverCardData.fullName"
                :state="getValidationState(validationContext)"
                placeholder="Іванов Іван Іванович"
                readonly
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Номер телефону"
          >
            <b-form-group
              label="Номер телефону"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="driverCardData.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="380681234567"
                readonly
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              label="Тип рахунку"
              label-for="balance"
              class="mb-2"
            >
              
              <b-form-checkbox
                v-model="typeAccount1Active"
                name="type"
                value="1"
                class="mb-1"
                :required="!typeAccount1Active && !typeAccount2Active"
                v-if="checkAllowedAccountTypes(driverCardData.wallet.accounts, TYPE_ACCOUNT_CASH)"
              >
                Готівковий
              </b-form-checkbox>

              <div v-if="typeAccount1Active">

                <app-collapse class="mb-1 add-card-collapse-item">

                  <app-collapse-item title="Ліміти"> 

                    <b-form-group
                      label="Відображати ліміт у водія"
                      for="displayableTypePeriod"
                      class="mt-1"
                    >
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :value="cardsData[0].limits.displayableTypePeriod"
                        :options="displayableTypePeriodsOptions"
                        class="w-100"
                        :reduce="val => val.value"
                        name="displayableTypePeriod"
                        :clearable="false"
                        @input="(val) => cardsData[0].limits.displayableTypePeriod = val"

                      />
                    </b-form-group>

                    <table class="limits-table mt-1">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Паливні</th>
                          <th>Грошові</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>На день</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.goods.dayLimitValue" @input="changeLimit(0, 'day', 'goods')"/>
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.money.dayLimitValue" @input="changeLimit(0, 'day', 'money')"/>
                              <span>грн</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>На тиждень</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.goods.weekLimitValue" @input="changeLimit(0, 'week', 'goods')" />
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.money.weekLimitValue" @input="changeLimit(0, 'week', 'money')" />
                              <span>грн</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>На місяць</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.goods.monthLimitValue" @input="changeLimit(0, 'month', 'goods')"/>
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.money.monthLimitValue" @input="changeLimit(0, 'month', 'money')"/>
                              <span>грн</span>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td>Загальні</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.goods.totalLimitValue" @input="changeLimit(0, 'total', 'goods')"/>
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[0].limits.money.totalLimitValue" @input="changeLimit(0, 'total', 'money')" />
                              <span>грн</span>
                            </div>
                          </td>
                        </tr> -->
                      </tbody>
                    </table>


                  </app-collapse-item>
                </app-collapse>


                <app-collapse class="mb-1 add-card-collapse-item">

                  <app-collapse-item title="Доступні види палива"> 

                    <div class="d-flex flex-wrap mt-1">
                      <div class="mb-1 mr-3 fuel-checkbox-wrapper" v-for="fuel in fuels">
                        <b-form-checkbox
                          class="custom-control-success"
                          :value="fuel.value"
                          v-model="cardsData[0].fuels"
                        >
                        {{ getFuelsTitle(fuel.title) }}
                        </b-form-checkbox>  
                      </div>
                    </div>
                  
                  </app-collapse-item>
                </app-collapse>
              </div>

              <b-form-checkbox
                v-model="typeAccount2Active"
                name="type"
                value="2"
                class="mb-1"
                :required="!typeAccount1Active && !typeAccount2Active"
                v-if="checkAllowedAccountTypes(driverCardData.wallet.accounts, TYPE_ACCOUNT_CASHLESS)"
              >
                Безготівковий
              </b-form-checkbox>

              <div v-if="typeAccount2Active">

                <app-collapse class="mb-1 add-card-collapse-item">

                  <app-collapse-item title="Ліміти"> 

                    <b-form-group
                      label="Відображати ліміт у водія"
                      for="displayableTypePeriod"
                      class="mt-1"
                    >
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :value="cardsData[1].limits.displayableTypePeriod"
                        :options="displayableTypePeriodsOptions"
                        class="w-100"
                        :reduce="val => val.value"
                        name="displayableTypePeriod"
                        :clearable="false"
                        @input="(val) => cardsData[1].limits.displayableTypePeriod = val"

                      />
                    </b-form-group>

                    <table class="limits-table mt-1">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Паливні</th>
                          <th>Грошові</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>На день</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.goods.dayLimitValue" @input="changeLimit(1, 'day', 'goods')"/>
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.money.dayLimitValue" @input="changeLimit(1, 'day', 'money')" />
                              <span>грн</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>На тиждень</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.goods.weekLimitValue" @input="changeLimit(1, 'week', 'goods')"/>
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.money.weekLimitValue" @input="changeLimit(1, 'week', 'money')"/>
                              <span>грн</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>На місяць</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.goods.monthLimitValue" @input="changeLimit(1, 'month', 'goods')"/>
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.money.monthLimitValue" @input="changeLimit(1, 'month', 'money')"/>
                              <span>грн</span>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td>Загальні</td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.goods.totalLimitValue" @input="changeLimit(1, 'total', 'goods')" />
                              <span>л</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-end">
                              <b-form-input class="limit-input" v-model.number="cardsData[1].limits.money.totalLimitValue" @input="changeLimit(1, 'total', 'money')" />
                              <span>грн</span>
                            </div>
                          </td>
                        </tr> -->
                      </tbody>
                    </table>

                  </app-collapse-item>
                </app-collapse>


                <app-collapse class="mb-1 add-card-collapse-item">

                  <app-collapse-item title="Доступні види палива"> 

                    <div class="d-flex flex-wrap mt-1">
                      <div class="mb-1 mr-3 fuel-checkbox-wrapper" v-for="fuel in fuels">
                        <b-form-checkbox
                          class="custom-control-success"
                          :value="fuel.value"
                          v-model="cardsData[1].fuels"
                        >
                        {{ getFuelsTitle(fuel.title) }}
                        </b-form-checkbox>  
                      </div>
                    </div>
                  </app-collapse-item>
                </app-collapse>
            </div>

          </b-form-group>



          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Додати
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Відмінити
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from "@/libs/axios";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {useBusinessSettings} from '@/composition/businessSettings/useBusinessSettings';
import { useFuels } from "@/composition/extra/useFuels";

const TYPE_ACCOUNT_CASH = 1;
const TYPE_ACCOUNT_CASHLESS = 2;

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDriverCardSidebarActive',
    event: 'update:is-add-new-driver-card-sidebar-active',
  },
  props: {
    isAddNewDriverCardSidebarActive: {
      type: Boolean,
      required: true,
    },
    driverCardData: {
      type: Object,
      required: true,
    },
    isModalCardSuccess: {
      type: Boolean,
      required: false,
    },
    isShowCreateCard: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      TYPE_ACCOUNT_CASH,
      TYPE_ACCOUNT_CASHLESS,
    }
  },
  methods: {
    checkAllowedAccountTypes(accounts, needleType) {
      if (!this.accountTypes) {
        return false;
      }

      const isAllowed = this.accountTypes.filter(type => type === needleType).length === 1;
      if (!isAllowed) {
        return false;
      }

      const isNotExisting = accounts.filter(account => account.typeAccount === needleType).length === 0;

      return isNotExisting;
    },
  },

  setup(props, { emit }) {
    const { accountTypes } = useBusinessSettings();
    const toast = useToast()

    const { getFuelTitleShort, fuels } = useFuels();

    const typeAccount1Active = ref(false)
    const typeAccount2Active = ref(false)

    const blankCardData = [
      {
        typeAccount: 1,
        limits: {
          goods: {
            dayLimitActive: false,
            weekLimitActive: false,
            monthLimitActive: false,
            totalLimitActive: false,
            dayLimitValue: 0,
            weekLimitValue: 0,
            monthLimitValue: 0,
            dayLimitRest: 0,
            weekLimitRest: 0,
            monthLimitRest: 0,
            totalLimitRest: 0
          },
          money: {
            dayLimitActive: false,
            weekLimitActive: false,
            monthLimitActive: false,
            totalLimitActive: false,
            dayLimitValue: 0,
            weekLimitValue: 0,
            monthLimitValue: 0,
            dayLimitRest: 0,
            weekLimitRest: 0,
            monthLimitRest: 0,
            totalLimitRest: 0
          },
          displayableTypePeriod: 0,
        },
        fuels: []
      },
      {
        typeAccount: 2,
        limits: {
          goods: {
            dayLimitActive: false,
            weekLimitActive: false,
            monthLimitActive: false,
            totalLimitActive: false,
            dayLimitValue: 0,
            weekLimitValue: 0,
            monthLimitValue: 0,
            dayLimitRest: 0,
            weekLimitRest: 0,
            monthLimitRest: 0,
            totalLimitRest: 0
          },
          money: {
            dayLimitActive: false,
            weekLimitActive: false,
            monthLimitActive: false,
            totalLimitActive: false,
            dayLimitValue: 0,
            weekLimitValue: 0,
            monthLimitValue: 0,
            dayLimitRest: 0,
            weekLimitRest: 0,
            monthLimitRest: 0,
            totalLimitRest: 0
          },
          displayableTypePeriod: 0,
        },
        fuels: []
      },
    ]

    const formatLimitPriceGoods = (value) => {
      return value * 1000
    }
    const formatLimitPriceMoney = (value) => {
      return value * 100
    }

    const displayableTypePeriodsOptions = [
      { label: 'За замовчуванням', value: 0 },
      { label: 'За день', value: 1 },
      { label: 'За тиждень', value: 2 },
      { label: 'За місяць', value: 3 },
      { label: 'За весь час', value: 4 },
    ]

    const cardsData = ref(JSON.parse(JSON.stringify(blankCardData)))
    const resetCardData = () => {
      typeAccount1Active.value = false
      typeAccount2Active.value = false
      cardsData.value = JSON.parse(JSON.stringify(blankCardData))
    }

    const onSubmit = () => {

      let cardDataSend = []

      if (typeAccount1Active.value) {
        cardDataSend.push(JSON.parse(JSON.stringify(cardsData.value[0])))
      }

      if (typeAccount2Active.value) {
        cardDataSend.push(JSON.parse(JSON.stringify(cardsData.value[1])))
      }

      let isEmptyLimits = false
      let isEmptyFuels = false

      cardDataSend.forEach(cardSend => {
          cardSend.limits.goods.dayLimitValue = formatLimitPriceGoods(cardSend.limits.goods.dayLimitValue)
          cardSend.limits.goods.weekLimitValue = formatLimitPriceGoods(cardSend.limits.goods.weekLimitValue)
          cardSend.limits.goods.monthLimitValue = formatLimitPriceGoods(cardSend.limits.goods.monthLimitValue)

          cardSend.limits.money.dayLimitValue = formatLimitPriceMoney(cardSend.limits.money.dayLimitValue)
          cardSend.limits.money.weekLimitValue = formatLimitPriceMoney(cardSend.limits.money.weekLimitValue)
          cardSend.limits.money.monthLimitValue = formatLimitPriceMoney(cardSend.limits.money.monthLimitValue)

          if (!cardSend.limits.goods.dayLimitValue && !cardSend.limits.goods.weekLimitValue
            && !cardSend.limits.goods.monthLimitValue
            && !cardSend.limits.money.dayLimitValue && !cardSend.limits.money.weekLimitValue
            && !cardSend.limits.money.monthLimitValue
          ) {
            if (props.driverCardData.balanceType == 1) {
              delete cardSend.limits;
            } else {
              isEmptyLimits = true
              return
            }
          }

          if (!cardSend.fuels.length) {
            isEmptyFuels = true
            return
          }
      });

      if (isEmptyLimits) {

        toast({
            component: ToastificationContent,
            props: {
                title: 'Потрібно ввести хоч один ліміт',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
        })
        return
      }

      if (isEmptyFuels) {

        toast({
            component: ToastificationContent,
            props: {
                title: 'Потрібно вибрати хоч один тип палива',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
        })
        return
      }

      store.dispatch('app-driver/addCard', {
        profileId: props.driverCardData.profileId, 
        cardsData: cardDataSend,
      })
        .then(() => {
          emit('refetch-data')
          emit('update:is-modal-card-success', true)
          emit('update:is-show-create-card', false)
          emit('update:is-add-new-driver-card-sidebar-active', false)
        }).catch(() => {
          toast({
              component: ToastificationContent,
              props: {
                  title: 'Неправильні дані або несумісний статус водія',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    }

    const changeLimit = (cardId, period, type) => {

      if (period == 'day') {
        if (type == 'goods') {
          cardsData.value[cardId].limits.money.dayLimitActive = false
          cardsData.value[cardId].limits.money.dayLimitValue = 0
          cardsData.value[cardId].limits.goods.dayLimitActive = true
        } else if (type == 'money') {
          cardsData.value[cardId].limits.goods.dayLimitActive = false
          cardsData.value[cardId].limits.goods.dayLimitValue = 0
          cardsData.value[cardId].limits.money.dayLimitActive = true  
        }
      } else if (period == 'week') {
        if (type == 'goods') {
          cardsData.value[cardId].limits.money.weekLimitActive = false
          cardsData.value[cardId].limits.money.weekLimitValue = 0
          cardsData.value[cardId].limits.goods.weekLimitActive = true
        } else if (type == 'money') {
          cardsData.value[cardId].limits.goods.weekLimitActive = false
          cardsData.value[cardId].limits.goods.weekLimitValue = 0
          cardsData.value[cardId].limits.money.weekLimitActive = true  
        }
      } else if (period == 'month') {
        if (type == 'goods') {
          cardsData.value[cardId].limits.money.monthLimitActive = false
          cardsData.value[cardId].limits.money.monthLimitValue = 0
          cardsData.value[cardId].limits.goods.monthLimitActive = true
        } else if (type == 'money') {
          cardsData.value[cardId].limits.goods.monthLimitActive = false
          cardsData.value[cardId].limits.goods.monthLimitValue = 0
          cardsData.value[cardId].limits.money.monthLimitActive = true  
        }
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCardData)

    return {
      accountTypes,
      cardsData,
      onSubmit,

      typeAccount1Active,
      typeAccount2Active,

      displayableTypePeriodsOptions,

      toast,

      refFormObserver,
      getValidationState,
      resetForm,

      changeLimit,
      getFuelsTitle: getFuelTitleShort,
      fuels,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-driver-card-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.limit-input {
  width: 80px;
  margin: 0 5px;
  text-align: center;
}

.limits-table {
  width: 100%;
  margin-bottom: 10px;
}

.limits-table tr,
.limits-table tr input {
  font-size: 12px;
}

.limits-table th {
  background: #f8f8f8;
}

.dark-layout .limits-table th {
  background: #343d55;
}

.limits-table th,
.limits-table td {
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #f8f8f8;
}

.dark-layout .limits-table th,
.dark-layout .limits-table td{
  border-bottom: 1px solid #343d55;
}

.add-card-collapse-item .card {
  box-shadow: none;
}

.add-card-collapse-item .card-body {
  width: 100%;
  padding: 0 !important;
}

.add-card-collapse-item .card .card-header {
  background: #f8f8f8;
  border-radius: 0;
}

.dark-layout .add-card-collapse-item .card .card-header {
  background: #343d55;
}

.add-card-collapse-item .collapse-title {
  font-size: 14px !important;
  font-weight: 400;
}

.fuel-checkbox-wrapper {
  width: 20%;
}

</style>
