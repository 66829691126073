<template>

  <div>

    <driver-list-add-new
      :is-add-new-driver-sidebar-active.sync="isAddNewDriverSidebarActive"
      :balance-options="balanceOptions"
      :group-options="groupOptions"
      @refetch-data="refetchData"
    />

    <driver-card-add-new
      :is-add-new-driver-card-sidebar-active.sync="isAddNewDriverCardSidebarActive"
      :driver-card-data="driverCardData"
      @refetch-data="refetchData"
      :is-modal-card-success.sync="isModalCardSuccess"
      :is-show-create-card.sync="isShowCreateCard"
    />

    <b-modal
      id="modal-success-card"
      ok-only
      v-model="isModalCardSuccess"
      ok-variant="success"
      ok-title="Зрозуміло"
      modal-class="modal-success-card"
      centered
      title="Картку створено"
    >
      <b-card-text>
        Картку створено успішно! Очікуйте, коли вона зʼявиться у кабінеті 😊
      </b-card-text>
    </b-modal>

    <!-- Filters -->
    <drivers-list-filters
      :balance-filter.sync="driversFilters.balanceType"
      :group-filter.sync="driversFilters.group"
      :status-filter.sync="driversFilters.status"
      :balance-options="balanceOptions"
      :group-options="groupOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-skeleton-wrapper :loading="driversLoading">
        <template #loading>
          <b-skeleton-table
              :rows="10"
              :columns="7"
              :table-props="{ bordered: true }"
          ></b-skeleton-table>
        </template>

        <div class="m-2">

        <!-- Table Top -->
        <b-row class="align-items-end">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показано</label>
            <v-select
              v-model="driversPagination.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>записів</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="searchbar d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="driversFilters.search"
                class="d-inline-block mr-1"
                placeholder="Пошук..."
              />
              <b-button
                variant="info"
                class="mr-1"
                :to="{name: 'apps-drivers-list-contact'}"
              >
                <span class="text-nowrap">Звʼязатись з водіями</span>
              </b-button>
              <b-button
                variant="primary"
                @click="isAddNewDriverSidebarActive = true"
              >
                <span class="text-nowrap">Додати водія</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      </b-skeleton-wrapper>

      <b-table
        ref="refUserListTable"
        class="position-relative drivers-list-table"
        :items="driversFiltered"
        v-bind="getTableAttributes"
        :fields="filteredColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Відповідних записів не знайдено"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Driver -->
        <template #cell(driver)="data">
          <b-media vertical-align="center" class="custom-media-cell">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserBalanceVariant(data.item.balanceType)}`"
                :to="{ name: 'apps-drivers-view', params: { id: data.item.profileId } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-drivers-view', params: { id: data.item.profileId } }"
              class="font-weight-bold d-block"
            >
              {{ data.item.fullName }}
            </b-link>
            <!-- <small class="text-muted">@{{ data.item.username }}</small> -->
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Group -->
        <template #cell(groups)="data">
          <b-link
              v-for="group in data.item.groups"
              :key="group.id"
              :to="{ name: 'apps-groups-view', params: { id: group.id } }"
          >
            <b-badge
              pill
              :variant="`light-primary`"
              class="text-capitalize"
            >
              {{ group.title }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: isAllowSelfPay -->
        <!-- <template #cell(isAllowSelfPay)="data">
          <b-form-checkbox
            v-model="data.item.isAllowSelfPay"
            class="custom-control-success"
            @change="changeIsAllowSelfPay(data.item)"
          >
          </b-form-checkbox>
        </template> -->

        

        <!-- Column: Balance -->
        <template #cell(balanceType)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserBalanceVariant(data.item.balanceType)}`"
            class="text-capitalize"
          >
            {{ resolveUserBalance(data.item.balanceType) }}
          </b-badge>
        </template>

        <!-- Column: Cards -->
        <template #cell(cards)="data">
          <AccountCards
            :data="data"
            @openModal="showDriverCardSidebar($event)"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="actions-column">
            <b-dropdown
              variant="link"
              class="dropdown-btn"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-drivers-view', params: { id: data.item.profileId } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Подробиці</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-drivers-edit', params: { id: data.item.profileId } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Редагувати</span>
              </b-dropdown-item>

              <b-dropdown-item @click="blockDriver(data.item.profileId)" v-if="data.item.status !== 'BLOCKED'">
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">Заблокувати</span>
              </b-dropdown-item>
              <b-dropdown-item @click="unblockDriver(data.item.profileId)" v-else-if="data.item.status === 'BLOCKED'">
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">Розблокувати</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Видалити</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <!-- Pagination -->
      <DriversPagination :pagination="driversPagination" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, VBPopover, BFormCheckbox, BModal, BCardText,
  BSkeletonWrapper, BSkeletonTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import DriversListFilters from './DriversListFilters.vue'
import useDriversList from './useDriversList'
import driverStoreModule from '../driverStoreModule'
import DriverListAddNew from './DriverListAddNew.vue'
import DriverCardAddNew from '../DriverCardAddNew.vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from "@axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AccountCards from "@/views/apps/driver/drivers-view/components/AccountCards.vue";
import DriversPagination from "@/views/apps/driver/drivers-list/DriversPagination.vue";
import { useDrivers } from "@/composition/businessUsers/useDrivers";

export default {
  components: {
    DriversListFilters,
    DriverListAddNew,
    DriverCardAddNew,
    AccountCards,
    DriversPagination,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BModal, 
    BCardText,
    BSkeletonWrapper,
    BSkeletonTable,

    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      groupOptions: [],
      screenWidth: window.innerWidth,
    }
  },
  methods: {
    async changeIsAllowSelfPay(driver) {
      return await axiosIns
        .put(`/business/drivers/${driver.profileId}`, {
          fullName: driver.fullName,
          isAllowSelfPay: driver.isAllowSelfPay,
        })
        .then(() => {
          this.refetchData()
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  computed: {
    isSmallScreen() {
      return this.screenWidth < 768;
    },
    getTableAttributes() {
      return this.screenWidth < 768 ? { stacked: true } : { responsive: true };
    },
    filteredColumns() {
      if (this.isSmallScreen) {
        return this.tableColumns.filter(column => column.mobile);
      } else {
        return this.tableColumns;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  setup() {

    const toast = useToast();

    const isModalCardSuccess = ref(false)
    const isShowCreateCard = ref(true)

    const { driversPagination, driversLoading, driversFilters, blockDriver, unblockDriver } = useDrivers();

    const DRIVER_APP_STORE_MODULE_NAME = 'app-driver'

    // Register module
    if (!store.hasModule(DRIVER_APP_STORE_MODULE_NAME)) store.registerModule(DRIVER_APP_STORE_MODULE_NAME, driverStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DRIVER_APP_STORE_MODULE_NAME)) store.unregisterModule(DRIVER_APP_STORE_MODULE_NAME)
    })

    const isAddNewDriverSidebarActive = ref(false)
    const isAddNewDriverCardSidebarActive = ref(false)

    const balanceOptions = [
      { label: 'Баланс', value: '1' },
      { label: 'Ліміт', value: '2' },
    ]

    const statusOptions = [
      { value: 'WAITING_FOR_USER_REGISTRATION', label: 'Очікується реєстрація користувача' },
      { value: 'ACCOUNT_PROCESSING', label: 'Обробка' },
      { value: 'WAITING_DRIVER_PIN_CONFIRMATION', label: 'Очікується підтвердження PIN-коду' },
      { value: 'CONFIRMED', label: 'Підтверджено' },
      { value: 'PENDING_DATEX_CREATION', label: 'Очікується створення DATEX' },
      { value: 'PREPARED', label: 'Готовий' },
      { value: 'ACTIVE', label: 'Активний' },
      { value: 'BLOCKED', label: 'Заблокований' },
    ]

    const {
      driversFiltered,
      tableColumns,
      perPage,
      currentPage,
      totalDrivers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserBalanceVariant,
      resolveUserBalance,
      resolveUserStatusVariant,
      resolveUserStatus,

      // Extra Filters
      balanceFilter,
      groupFilter,
      statusFilter,
    } = useDriversList()

    const internalInstance = getCurrentInstance()

    store.dispatch('app-driver/fetchGroups', {})
      .then(response => {

        const groups = response.data.items
        let groupsOptionsData = []

        groups.forEach(group => {
          groupsOptionsData.push({
            label: group.title,
            value: group.id.toString()
          })
        })

        groups.value = response.data.items
        internalInstance.data.groupOptions = groupsOptionsData
      })
      .catch(error => {
        return false
      })

    const driverCardData = ref({
      fullName: '123',
      phone: '123',
      isActive: false,
      balanceType: '1',
      wallet: {
        accounts: [],
      }
    })

    const showDriverCardSidebar = (data) => {
      isAddNewDriverCardSidebarActive.value = true;
      driverCardData.value = data
    }

    return {

      // Sidebar
      isAddNewDriverSidebarActive,
      isAddNewDriverCardSidebarActive,
      driverCardData,

      driversFiltered,
      tableColumns,
      perPage,
      currentPage,
      totalDrivers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      driversPagination,
      driversLoading,
      driversFilters,
      blockDriver,
      unblockDriver,

      // Filter
      avatarText,

      // UI
      resolveUserBalanceVariant,
      resolveUserBalance,
      resolveUserStatusVariant,
      resolveUserStatus,
      showDriverCardSidebar,

      balanceOptions,
      // groupOptions,
      statusOptions,

      isModalCardSuccess,
      isShowCreateCard,

      // Extra Filters
      balanceFilter,
      groupFilter,
      statusFilter,
      toast,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .table td {
  display: flex;
  padding: 1rem 2rem;
}

.custom-checkbox .custom-control-input,
.custom-checkbox .custom-control-label {
  cursor: pointer;
}

.limits-tip p {
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
}

.limits-tip {
  margin: 10px 0;
}

.custom-media-cell {
  display: flex !important;
  align-items: center !important;
}
</style>

<style type="scss">
  @media screen and (max-width: 768px) {
    .custom-media-cell {
      display: flex !important;
      align-items: center !important;
    }
    .dropdown-btn button {
      padding: 0 1.5rem !important;
    }
    .searchbar {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 1rem;
    }
  }
</style>
