<template>
  <b-sidebar
    id="add-new-driver-sidebar"
    :visible="isAddNewDriverSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-driver-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Додати нового водія
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Повне імʼя"
          >
            <b-form-group
              label="Повне імʼя"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="driverData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Іванов Іван Іванович"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Номер телефону"
            rules="required"
          >
            <b-form-group
              label="Номер телефону"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="driverData.phone"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="380681234567"
                required
                pattern="^\+?3?8?(0[3-9][0-9]\d{7})$" title="Номер телефону повинен бути у форматі 380681234567"
                oninvalid="setCustomValidity('Номер телефону повинен бути у форматі 380681234567')"
                oninput="try{setCustomValidity('')}catch(e){}"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Код підтвердження"
            rules="required"
          >
            <b-form-group
              label="Код підтвердження"
              label-for="confirmationCode"
            >
              <b-form-input
                id="confirmationCode"
                v-model="driverData.confirmationCode"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="1234"
                required
                pattern="^\d{4}" title="Код може містити тільки 4 цифри"
                oninvalid="setCustomValidity('Код може містити тільки 4 цифри')"
                oninput="try{setCustomValidity('')}catch(e){}"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              label="Тип рахунку"
              label-for="balance"
              class="mb-2"
            >
              <b-form-radio
                v-model="driverData.typeBalance"
                name="typeBalance"
                value="1"
                class="mb-1"
                required
              >
                Баланс
              </b-form-radio>
              <b-form-radio
                v-model="driverData.typeBalance"
                name="typeBalance"
                value="2"
              >
                Ліміт
              </b-form-radio>

          </b-form-group>

          <!-- <b-form-group
              label="Тип рахунку"
              label-for="accounts"
              class="mb-2"
              v-if="driverData.typeBalance == 'balance'"
            >
              <b-form-checkbox
                v-model="accounts[0]"
                value="balance_cash"
                class="mb-1"
              >
                Готівковий
              </b-form-checkbox>
              <b-form-checkbox
                v-model="accounts[1]"
                value="balance_cashless"
              >
                Безготівковий
              </b-form-checkbox>

          </b-form-group> -->

          <!-- <b-form-group
              label="Аккаунт"
              label-for="accounts"
              class="mb-2"
            >
            <b-form-checkbox
              v-model="driverData.isActive"
            >
              Активний
            </b-form-checkbox>
          </b-form-group> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Додати
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Відмінити
            </b-button>
          </div>
          <small class="text-danger mt-1 d-block" v-if="driverData.error">Неправильні дані або водій вже існує</small>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormRadio, BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import 'cleave.js/dist/addons/cleave-phone.us'
import { useDrivers } from "@/composition/businessUsers/useDrivers";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,
    BInputGroup,

    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDriverSidebarActive',
    event: 'update:is-add-new-driver-sidebar-active',
  },
  props: {
    isAddNewDriverSidebarActive: {
      type: Boolean,
      required: true,
    },
    balanceOptions: {
      type: Array,
      required: true,
    },
    groupOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankDriverData = {
      fullName: '',
      phone: '',
      typeBalance: 0,
      error: false,
      confirmationCode: '',
    }

    const { addDriver } = useDrivers();

    const driverData = ref(JSON.parse(JSON.stringify(blankDriverData)))
    const resetdriverData = () => {
      driverData.value = JSON.parse(JSON.stringify(blankDriverData))
    }

    const accounts = [false, false]

    const onSubmit = () => {

      const data = {
        fullName: driverData.value.fullName,
        phone: driverData.value.phone,
        typeBalance: parseInt(driverData.value.typeBalance),
        confirmationCode: driverData.value.confirmationCode,
      }
      addDriver(data)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-driver-sidebar-active', false)
        }).catch(() => {
          driverData.value.error = true
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdriverData)

    return {
      driverData,
      onSubmit,
      accounts,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-driver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
