<template>
  <div class="mx-2 mb-2">
    <b-row>

      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">Показано {{ pagination.from }} до {{ pagination.to }} з {{ pagination.total }} записів</span>
      </b-col>

      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BPagination } from "bootstrap-vue";

export default {
  name: "DriversPagination",

  components: {
    BRow,
    BCol,
    BPagination,
  },

  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
}
</script>
